import { useState } from "react";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import TextField from "@mui/material/TextField";
// import IconButton from "@mui/material/Button";
// import SendIcon from "@mui/icons-material/Send";
// import createRaceComment from "../../functions/createRaceComment";
import { getDatabase, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { useListVals } from "react-firebase-hooks/database";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import CommentIcon from "@mui/icons-material/Comment";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import moment from "moment";
import {
  toggleComments,
  changeNavBarColor,
  changeNavBarTextColor,
} from "../../features/user/userSlice";
import { useSelector, useDispatch } from "react-redux";

const firebaseConfig = {
  apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
  authDomain: "racingpicks-b8b05.firebaseapp.com",
  databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
  projectId: "racingpicks-b8b05",
  storageBucket: "racingpicks-b8b05.appspot.com",
  messagingSenderId: "975858243227",
  appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
};

export default function RaceDetails(props) {
  const { race, updateBorder, tabRaceLink, raceDetails } = props;
  const initialDrawer = useSelector((state) => state.user.viewComments);
  const [drawerOpen, setDrawerOpen] = useState(initialDrawer);
  //const [comment, setComment] = useState(null);
  let comments = null;
  let numberOfComments = null;
  //const { currentUser, allUsers: users } = useSelector((state) => state.user);
  const { allUsers: users } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const toggleDrawer = (newOpen) => {
    dispatch(toggleComments(!drawerOpen));
    setDrawerOpen(!drawerOpen);
  };

  let borderColor = "black";
  let navBarColor = "#1976d2";
  let navBarTextColor = "white";
  if (race?.groupRace === "G1") {
    borderColor = "gold";
    navBarColor = "gold";
    navBarTextColor = "black";
  } else if (race?.groupRace === "G2") {
    borderColor = "silver";
    navBarColor = "silver";
    navBarTextColor = "black";
  } else if (race?.groupRace === "G3") {
    borderColor = "orange";
    navBarColor = "orange";
    navBarTextColor = "black";
  }
  updateBorder(borderColor);
  dispatch(changeNavBarColor(navBarColor));
  dispatch(changeNavBarTextColor(navBarTextColor));

  let trackName = race.meetingVenue.split(" Races")[0].replace(" ", "");

  /*
  function submitComment() {
    if (comment != null) {
      createRaceComment(currentUser, comment, race.raceID);
      setComment(null);
    }
  }
  */

  // Get comments from firebase
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);

  const [rawComments] = useListVals(
    ref(database, "raceComments/" + race.raceID)
  );

  const raceComment = raceDetails?.comment;

  if (rawComments != null) {
    let sortedComments = rawComments;
    sortedComments.sort((a, b) => b.timeStamp - a.timeStamp);
    numberOfComments = sortedComments.length;

    comments = sortedComments.map((note) => (
      <CardContent
        sx={{
          textAlign: "left",
          width: "100%",
          padding: "10px 16px",
          boxSizing: "border-box",
          display: "flex",
          flex: 1,
        }}
      >
        <Avatar
          sx={{
            bgcolor: users.find((a) => a.name === note.user).color,
            borderColor: "black",
            marginRight: "10px",
            marginTop: "5px",
          }}
          src={users.find((a) => a.name === note.user).avatar}
          key={users.find((a) => a.name === note.user).name}
        >
          {users.find((a) => a.name === note.user).initials}
        </Avatar>
        <div>
          <Typography variant="body2" component="body2">
            {note.user} - {moment(note.timeStamp).format("MMM Do LT")}
          </Typography>
          <br></br>
          <Typography variant="body1" component="body1">
            {note.comment}
          </Typography>
        </div>
      </CardContent>
    ));
  }

  return (
    <Accordion
      expanded={drawerOpen}
      sx={{
        margin: "0px",
        "& .MuiAccordionSummary-contentGutters": {
          margin: "0px",
        },
        "& .MuiAccordionSummary-content": {
          margin: "0px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          margin: "0px",
        },
        boxShadow: "none",
        color: "white",
      }}
    >
      <AccordionSummary
        style={{ backgroundColor: "black", padding: "0px", margin: "0px" }}
        onClick={() => toggleDrawer(true)}
      >
        <div
          style={{
            height: "230px",
            backgroundImage: `url(/RaceTracks/${trackName}.jpg)`,
            backgroundSize: "cover",
            width: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              zIndex: "2",
              fontWeight: "bold",
              textShadow: "3px 3px 4px black",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: "18px",
                textAlign: "left",
                padding: "10px",
                flex: 1,
              }}
            >
              {raceDetails?.description}
              <div style={{ fontSize: "16px" }}>
                <br></br>
                {race?.meetingVenue} - Race {race?.race_number}
                <br></br>Distance: {race?.distance}m<br></br>Stake:{" "}
                {raceDetails?.prize_monies?.total_value}
              </div>
            </div>
            <div
              style={{
                textAlign: "left",
                padding: "10px",
                fontSize: "16px",
                display: "flex",
              }}
            >
              <div style={{ flex: 1 }}>
                Track:{raceDetails?.track_condition}
                <br></br>
                Weather: {raceDetails?.weather}
              </div>
              <div style={{ alignItems: "flex-end", display: "flex" }}>
                <CommentIcon></CommentIcon>
                <span style={{ marginLeft: "10px" }}>{numberOfComments}</span>
              </div>
              <div style={{ alignItems: "flex-end", display: "flex" }}>
                <NotificationsOffIcon></NotificationsOffIcon>
              </div>
            </div>
            <Button
              href={tabRaceLink}
              target="_blank"
              style={{ display: "none" }}
            >
              View on TAB
            </Button>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: "white",
          padding: "5px",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <Card
            sx={{
              flex: 1,
              margin: "0px 0px 0px 0px",
              width: "100%",
              borderRadius: "0px",
              display: "flex",
              overflow: "initial",
              textAlign: "right",
              flexDirection: "column",
              boxShadow: "none",
            }}
          >
            {/*
            <CardContent
              sx={{
                textAlign: "left",
                width: "100%",
                padding: "15px 5px",
                boxSizing: "border-box",
                display: "flex",
              }}
            >
              <TextField
                id="outlined-multiline-static"
                label="Add comment"
                multiline
                value={comment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setComment(event.target.value);
                }}
                fullWidth
              />
              <IconButton
                aria-label="submit"
                onClick={submitComment}
                disabled={comment == null}
              >
                <SendIcon />
              </IconButton>
            </CardContent>
              */}
            {comments != null ? (
              <div
                style={{
                  textAlign: "left",
                  padding: "20px",
                  fontSize: "14px",
                  lineHeight: "1.1rem",
                }}
              >
                {raceComment}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "20px",
                  marginTop: "15px",
                }}
              >
                No comments yet...
              </div>
            )}
          </Card>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
