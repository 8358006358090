import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { useState } from "react";
import moment from "moment";
import { useGetScheduleQuery } from "../../features/api/apiSlice";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import MeetingHeader from "./MeetingHeader";

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

export default function MeetingRaceDrawer(props) {
  const { trifecta } = props;
  const navigate = useNavigate();
  const returnNav = false;
  const [drawerOpen, setDrawerOpen] = useState(false);
  let { raceNumber, raceMeeting, date } = useParams();
  raceNumber = Number(raceNumber);
  raceMeeting = Number(raceMeeting);

  const { data } = useGetScheduleQuery(moment(date).format("YYYY-MM-DD"));

  const raceMeetingDetails = data?.meetings.find(
    ({ tote_meeting_number }) => tote_meeting_number === raceMeeting
  );

  // const updateDate = (date) => {
  //setDate(date);
  // updateSchedDate(moment(date).day("Saturday").format("YYYY-MM-DD"));
  //};

  const meetings = data?.meetings.map((a) => {
    return (
      <option value={a.tote_meeting_number} key={a.tote_meeting_number}>
        M{a.tote_meeting_number} {a.name}
      </option>
    );
  });

  const races = data?.meetings
    .filter((a) => {
      return a.tote_meeting_number === raceMeeting;
    })[0]
    ?.races.map((a) => {
      let borderColor = "blue";

      if (a.groupRace === "G1") {
        borderColor = "gold";
      } else if (a.groupRace === "G2") {
        borderColor = "silver";
      } else if (a.groupRace === "G3") {
        borderColor = "orange";
      }

      return (
        <div
          style={{
            margin: "10px",
            flex: "1 1 0%",
            border: "solid 1px",
            borderColor: borderColor,
            padding: "10px",
            borderRadius: "10px",
            flexDirection: "column",
            display: "flex",
            textAlign: "left",
          }}
          variant={a.race_number === raceNumber ? "contained" : "outlined"}
          onClick={() => navigate(`race/${a.race_number}`)}
          key={a.race_number}
        >
          <div
            style={{
              display: "flex",
              flex: "1",
              flexDirection: "column",
              fontSize: "14px",
            }}
          >
            <span>
              Race {a.race_number} - {a.name}
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flex: "1",
                flexDirection: "row",
                fontSize: "14px",
              }}
            >
              <div style={{ flex: "1" }}>Distance: {a.distance}m</div>
              <div style={{ flex: "1" }}>{a.stake}</div>
            </div>
          </div>
        </div>
      );
    });

  const display = returnNav ? "none" : "initial";

  return (
    <div
      style={{
        backgroundColor: "#282c34",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
        color: "white",
        flex: "1",
        scrollMarginTop: "20px",
      }}
    >
      <div
        expanded={drawerOpen}
        style={{
          display: display,
          zIndex: 11,
          width: "100%",
        }}
        sx={{
          margin: "0px",
          "& .MuiAccordionSummary-contentGutters": {
            margin: "0px",
          },
          boxShadow: "none",
          color: "white",
        }}
      >
        <div>
          <Root>
            <StyledBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                color: "black",
              }}
            >
              <FormControl fullWidth>
                <NativeSelect
                  onChange={(event) => {
                    if (trifecta) {
                      navigate(
                        `/trifecta/${date}/meeting/${event?.target.value}`
                      );
                    } else
                      navigate(`/races/${date}/meeting/${event?.target.value}`);

                    setDrawerOpen(false);
                  }}
                  sx={{ padding: "10px" }}
                  value={raceMeeting}
                >
                  {meetings}
                </NativeSelect>
              </FormControl>
              {trifecta && (
                <div style={{ width: "100%", display: "flex" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{ width: "100%", flex: "1", margin: "10px" }}
                    color="secondary"
                  >
                    $$$ Trifectas $$$
                  </Button>
                </div>
              )}
              {raceMeetingDetails && (
                <MeetingHeader meeting={raceMeetingDetails} />
              )}
              <h3 style={{ marginTop: "20px", margin: "10px" }}>Races</h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  width: "100%",
                  paddingBottom: "200px",
                }}
              >
                {races}
              </div>
            </StyledBox>
          </Root>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
