import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { styled } from "@mui/material/styles";
import { addTrifectaHorse } from "../functions/addTrifectaHorse";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { getDatabase, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { useListVals } from "react-firebase-hooks/database";
import { Link } from "react-router-dom";
import { useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
  authDomain: "racingpicks-b8b05.firebaseapp.com",
  databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
  projectId: "racingpicks-b8b05",
  storageBucket: "racingpicks-b8b05.appspot.com",
  messagingSenderId: "975858243227",
  appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export default function HorseCardTrifecta(props) {
  const [isShowingMore, setIsShowingMore] = useState(false);
  //const showMoreCharacters = 100;
  const { horse, favourites, raceForm, currentTab, databaseReferenceString } =
    props;

  const { currentUser, allUsers: users } = useSelector((state) => state.user);

  const horseForm = raceForm?.[horse.id];

  const favId = favourites?.find(
    (o) => o.selection.name === horse.name && o.trifecta?.length > 0
  );
  let icon = <StarBorderIcon fontSize="inherit" />;
  let bgColor = "white";
  let avatars = null;

  if (favId !== undefined) {
    avatars = favId.trifecta?.map((user) => (
      <Avatar
        sx={{ bgcolor: users.find((a) => a.name === user).color }}
        src={users.find((a) => a.name === user).avatar}
        key={users.find((a) => a.name === user).name}
      >
        {users.find((a) => a.name === user).initials}
      </Avatar>
    ));
    if (favId?.trifecta?.includes(currentUser)) {
      bgColor = "#c3dcc3";
      icon = <StarIcon style={{ color: "black" }} fontSize="inherit" />;
    } else if (!favId?.trifecta?.includes(currentUser)) {
      icon = null;
    }
  }
  const form = (
    <>
      <Typography
        sx={{ mb: 0.5, lineHeight: "initial" }}
        variant="overline"
        color="text.secondary"
        component="p"
      >
        J: {horse.jockey}
      </Typography>{" "}
      <Typography
        sx={{ mb: 0.5, lineHeight: "initial" }}
        variant="overline"
        color="text.secondary"
        component="p"
      >
        T: {horse.trainer_name}
      </Typography>
      <Typography variant="body2">
        {isShowingMore
          ? horseForm?.entrant_comment
          : horseForm?.entrant_comment + "..."}
        {isShowingMore ? (
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              marginLeft: "10px",
            }}
            onClick={(e) => {
              e.preventDefault();
              setIsShowingMore(false);
            }}
          >
            Show less
          </span>
        ) : (
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              marginLeft: "10px",
            }}
            onClick={(e) => {
              e.preventDefault();
              setIsShowingMore(true);
            }}
          >
            Show more
          </span>
        )}{" "}
      </Typography>
    </>
  );

  const trackTypes = () => {
    /*
    let trackType = [];
    const typesWeCareAbout = ["Distance", "Firm", "Good", "Soft", "Heavy"];
    typesWeCareAbout.forEach((a) => {
      const raceType = a.toUpperCase();
      let textToDisplay = a + ": ";
      const currentTrackType = horseDetails?.raceStatistics.find(
        (b) => b.type === raceType
      );
      textToDisplay += currentTrackType?.starts + ":";
      textToDisplay += currentTrackType?.firsts + "-";
      textToDisplay += currentTrackType?.seconds + "-";
      textToDisplay += currentTrackType?.thirds;
      trackType.push(textToDisplay);
    });
    */
    return [];
  };

  const extendedForm = (
    <div style={{ paddingTop: "5px" }}>
      {trackTypes().map((a) => (
        <Typography variant="body2" key={a}>
          {a}
        </Typography>
      ))}
    </div>
  );

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const [rawComments] = useListVals(
    ref(database, "horseComments/" + horse.name)
  );

  const ratings = (
    <>
      <StyledRating
        name="customized-color"
        defaultValue={3}
        getLabelText={(value: number) =>
          `${value} Heart${value !== 1 ? "s" : ""}`
        }
        precision={1}
        icon={<FavoriteIcon fontSize="inherit" />}
        sx={{ marginTop: "5px" }}
        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
      />
    </>
  );

  return (
    <Link
      to={`runner/${horse.runner_number}`}
      style={{ color: "inherit", textDecoration: "inherit" }}
    >
      <div style={{ display: "flex" }}>
        {rawComments?.length > 0 && (
          <Avatar
            sx={{
              position: "absolute",
              zIndex: "10",
              left: "1px",
              border: "solid white 2px",
              marginTop: "6px",
              backgroundColor: "#e50f0f",
              fontWeight: "bold",
              height: "30px",
              width: "30px",
            }}
          >
            {rawComments.length}
          </Avatar>
        )}
        <div
          style={{
            width: "90px",
            alignItems: "stretch",
            placeContent: "center",
            flexWrap: "wrap",
            margin: "10px 0px 0px 0px",
            overflow: "hidden",
            borderRadius: "5px 0px 0px 5px",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${horse.silk_url_128x128})`,
              backgroundSize: "340%",
              backgroundPosition: "center",
              backgroundPositionY: "-156px",
              width: "100%",
              height: "100%",
              filter: "blur(8px) brightness(0.85)",
              willChange: "filter",
              overflow: "hidden",
            }}
          ></div>
          <div
            style={{
              height: "100%",
              display: "flex",
              position: "relative",
              top: "-100%",
              boxSizing: "border-box",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Avatar
              src={horse.silk_url_128x128}
              sx={{
                width: 56,
                height: 56,
                backgroundColor: "white",
                border: "solid white 2px",
                margin: "0 auto",
                marginTop: "10px",
              }}
            ></Avatar>
            <Typography
              sx={{
                mb: 0.5,
                lineHeight: "initial",
                marginTop: "4px",
                fontWeight: "bold",
                fontSize: "0.94em",
                filter: "drop-shadow(3px 1px 5px black)",
                color: "white",
              }}
              variant="body2"
              color="text.secondary"
            >
              {horse?.last_twenty_starts.substring(0, 5)}
            </Typography>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#ffffffb3",
                padding: "15px 0px",
              }}
            >
              <Typography
                sx={{ mb: 0.5, lineHeight: "initial" }}
                variant="subtitle2"
                color="text.secondary"
              >
                W: ${horse.odds.fixed_win}
              </Typography>
              <Typography
                sx={{ mb: 0.5, lineHeight: "initial" }}
                variant="subtitle2"
                color="text.secondary"
              >
                P: ${horse.odds.fixed_place}
              </Typography>
            </div>
          </div>
        </div>
        <Card
          key={horse.name}
          sx={{
            flex: 1,
            margin: "10px 0px 0px 0px",
            width: "calc(100% - 110px)",
            borderRadius: "0px",
            backgroundColor: bgColor,
            display: "flex",
            overflow: "initial",
            textAlign: "right",
          }}
        >
          <CardContent
            sx={{ textAlign: "left", width: "100%", padding: "10px 16px" }}
          >
            <div style={{ display: "flex" }}>
              <Typography variant="h6" component="div" style={{ flex: 1 }}>
                {horse.runner_number}. {horse.name}
              </Typography>
              <IconButton
                aria-label="delete"
                color="primary"
                size="large"
                style={{ padding: "0px" }}
                onClick={(e) => {
                  e.preventDefault();
                  addTrifectaHorse(horse, currentUser, databaseReferenceString);
                }}
              >
                {icon}
              </IconButton>
            </div>
            {currentTab === "basic" && form}
            {currentTab === "extended" && extendedForm}
            {currentTab === "ratings" && ratings}
          </CardContent>
        </Card>
      </div>
      {avatars?.length > 0 && (
        <AvatarGroup
          max={4}
          sx={{
            alignSelf: "flex-end",
            marginRight: "9px",
            marginTop: "-25px",
          }}
        >
          {avatars}
        </AvatarGroup>
      )}
    </Link>
  );
}
