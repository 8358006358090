import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
  authDomain: "racingpicks-b8b05.firebaseapp.com",
  databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
  projectId: "racingpicks-b8b05",
  storageBucket: "racingpicks-b8b05.appspot.com",
  messagingSenderId: "975858243227",
  appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
};

export default function MessagingProvider(props) {
  const app = initializeApp(firebaseConfig);
  let messaging;
  try {
    messaging = getMessaging(app);
  } catch (err) {
    console.log("Error getting messaging provider");
  }
  const database = getDatabase(app);
  const [messagingAvailable, setMessagingAvailable] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState(false);
  const channel4Broadcast = new BroadcastChannel("channel4");

  if (messaging == null) {
    return props.children;
  }

  channel4Broadcast.onmessage = (event) => {
    console.log(event);
    if (event.data?.newUrl !== undefined)
      window.location.href = "WWW.GOOGLE.COM";
  };

  function askNotificationPermission() {
    return new Promise((resolve, reject) => {
      if (checkNotificationPromise()) {
        Notification.requestPermission().then(resolve);
      } else {
        Notification.requestPermission(resolve);
      }
    });
  }

  function checkNotificationPromise() {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }

    return true;
  }

  // Add the public key generated from the console here.
  getToken(messaging, {
    vapidKey:
      "BBH-PKnRQ-ex895GNycUHarNq-_3lfBcngzKvVBiZuhCCQr3J7ZKAhYnQZUYU0QXNXoF874Zt6pVjD-OeuqB6lg",
  })
    .then((currentToken) => {
      if (currentToken) {
        setMessagingAvailable(true);
        // Send the token to your server and update the UI if necessary
        onValue(ref(database, "users/Dan M"), (snapshot) => {
          const data = snapshot.val();
          let returnedTokens = data?.notificationTokens;
          if (returnedTokens === undefined) returnedTokens = [];
          else returnedTokens = Object.values(returnedTokens);
          if (!returnedTokens.includes(currentToken)) {
            returnedTokens.push(currentToken);
            set(
              ref(database, "users/Dan M/notificationTokens"),
              returnedTokens
            );
          }
        });
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });
  // inside the jsx being returned:

  onMessage(messaging, (payload) => {
    alert("you got mail");
    console.log(payload);
    setShow(false);
    setNotification(false);
  });

  return (
    <>
      {!messagingAvailable && (
        <div
          style={{
            position: "absolute",
            top: 100,
            zIndex: 200,
            width: "calc(100% - 40px",
            height: "50px",
            borderRadius: "5px",
            backgroundColor: "purple",
            display: "none",
          }}
          onClick={() => askNotificationPermission()}
        >
          Allow Notifications
        </div>
      )}
      {props.children}
      {show && <div>{notification}</div>}
    </>
  );
}
