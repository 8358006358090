import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  update,
  push,
  onValue,
  remove,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";

export function addTrifectaHorse(horse, user, databaseReferenceString) {
  // TODO: Replace the following with your app's Firebase project configuration
  // See: https://firebase.google.com/docs/web/learn-more#config-object
  const firebaseConfig = {
    apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
    authDomain: "racingpicks-b8b05.firebaseapp.com",
    databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
    projectId: "racingpicks-b8b05",
    storageBucket: "racingpicks-b8b05.appspot.com",
    messagingSenderId: "975858243227",
    appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const database = getDatabase(app);

  const { entrant_id } = horse;

  // Update the document

  const db = query(
    ref(database, databaseReferenceString),
    orderByChild("selection/number"),
    equalTo(entrant_id)
  );

  onValue(
    db,
    (snapshot) => {
      if (!snapshot.exists()) {
        push(ref(database, databaseReferenceString), {
          selection: horse,
          trifecta: [user],
        });
      } else {
        snapshot.forEach((childSnapshot) => {
          const currentItem = ref(
            database,
            databaseReferenceString + "/" + childSnapshot.key
          );
          const itemContents = childSnapshot.val();

          if (itemContents.trifecta != null) {
            if (itemContents?.trifecta?.includes(user)) {
              itemContents.trifecta = itemContents.trifecta.filter(
                (item) => item !== user
              );
            } else {
              itemContents.trifecta.push(user);
            }
          } else {
            itemContents.trifecta = [user];
          }

          if (
            itemContents.users === undefined &&
            itemContents.trifecta.length === 0
          ) {
            remove(currentItem);
          } else update(currentItem, itemContents);
        });
      }
    },
    {
      onlyOnce: true,
    }
  );
}
