import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import WhatsHotIcon from "@mui/icons-material/Whatshot";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { useGetScheduleQuery } from "../features/api/apiSlice";

export default function BottomNav(props) {
  const [state, setState] = React.useState(false);
  const { removeCookie } = props;
  const schedDate = moment().day("Saturday").format("YYYY-MM-DD");
  const urlDate = moment().day("Saturday").format("YYYYMMDD");
  const { data, isSuccess } = useGetScheduleQuery(schedDate);
  let firstMeetingObject = "";
  const toggleDrawer = () => () => {
    setState(!state);
  };

  if (isSuccess) {
    if (data?.meetings.length !== 0) {
      firstMeetingObject = data?.meetings?.reduce(function (prev, current) {
        return prev && prev.tote_meeting_number < current.tote_meeting_number
          ? prev
          : current;
      });
    }
  }

  const links = [
    { label: "Today's Meetings", icon: <RestoreIcon />, link: `/` },
    {
      label: "Saturday's Meetings",
      icon: <RestoreIcon />,
      link: `/races/${urlDate}/meeting/${firstMeetingObject.tote_meeting_number}`,
    },
    { label: "Next to Go", icon: <ArchiveIcon />, link: `/races/next` },
    { label: "My List", icon: <FavoriteIcon />, link: `/MyList` },
    { label: "Whats Hot", icon: <WhatsHotIcon />, link: `/WhatsHot` },
  ];

  const trifectaLinks = [
    {
      label: "Trifectas - All Meetings",
      icon: <EqualizerIcon />,
      link: `/trifecta/${urlDate}/meeting/${firstMeetingObject.tote_meeting_number}`,
    },
    {
      label: "Trifecta - Picks",
      icon: <FavoriteIcon />,
      link: `/TrifectaResults`,
    },
  ];

  return (
    <div>
      <IconButton size="large" edge="end" color="inherit" aria-label="menu">
        <MenuIcon onClick={toggleDrawer()} />
        <Drawer anchor={"right"} open={state} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}
          >
            <List>
              {links.map((linkItem, index) => (
                <ListItem key={linkItem.label} disablePadding>
                  <ListItemButton component={RouterLink} to={linkItem.link}>
                    <ListItemIcon>{linkItem.icon}</ListItemIcon>
                    <ListItemText primary={linkItem.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List>
              {trifectaLinks.map((linkItem, index) => (
                <ListItem key={linkItem.label} disablePadding>
                  <ListItemButton component={RouterLink} to={linkItem.link}>
                    <ListItemIcon>{linkItem.icon}</ListItemIcon>
                    <ListItemText primary={linkItem.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            <Divider />
            <List>
              <ListItem key={"logout"} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Log Out"}
                    onClick={() => removeCookie()}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </IconButton>
    </div>
  );
}
