import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getDatabase, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { useListVals } from "react-firebase-hooks/database";
import { useSelector } from "react-redux";
import { useGetScheduleQuery } from "../features/api/apiSlice";

const firebaseConfig = {
  apiKey: "AIzaSyBTgM9UQZJgy5WHtynvchywyfF0Klul4Ck",
  authDomain: "racingpicks-b8b05.firebaseapp.com",
  databaseURL: "https://racingpicks-b8b05-default-rtdb.firebaseio.com",
  projectId: "racingpicks-b8b05",
  storageBucket: "racingpicks-b8b05.appspot.com",
  messagingSenderId: "975858243227",
  appId: "1:975858243227:web:d435522fd5ab90f00dd83e",
};

export default function RacePage(props) {
  const navigate = useNavigate();
  const schedDate = moment().format("YYYY-MM-DD");
  const urlDate = moment().format("YYYYMMDD");

  const { currentUser } = useSelector((state) => state.user);

  const { data, isSuccess } = useGetScheduleQuery(schedDate);

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);

  const [latestNotification] = useListVals(
    ref(database, "users/" + currentUser + "/latestNotification/message")
  );

  if (latestNotification != null) {
    console.log(latestNotification);
  }

  useEffect(() => {
    if (isSuccess) {
      let firstMeetingObject = data?.meetings.reduce(function (prev, current) {
        return prev && prev.tote_meeting_number < current.tote_meeting_number
          ? prev
          : current;
      });
      navigate(
        `/races/${urlDate}/meeting/${firstMeetingObject.tote_meeting_number}`
      );
    }
  });

  return <div>Loading...</div>;
}
